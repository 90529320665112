import React, { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import cn from 'classnames';
import { css, StyleSheet } from 'aphrodite';
import Layout from '../components/Layout';
import { CUSTOMER_ASSESSMENT, CUSTOMER_DETAIL, CUSTOMER_IMAGE, PAYMENT_STUDENT } from '../urls';
import { useLoad, usePutRequest } from '../hooks/request';
import Table from '../components/common/Table';
import { PermissionDenied } from '../components/PermissionDenied';
import { checkPermission as cp } from '../utils/auth';
import { useModal } from '../hooks/modal';
import Loader from '../components/common/Loader';
import CustomerInfoGroupsCreate from '../components/CustomerInfoGroupsCreate';
import defaultAvatar from '../static/avatar_default.png';
import { domain } from '../utils/request';
import { DATE_FORMAT, getDateNotRU, getDateTime } from '../utils/date';
import CustomerInfoGroupsItem from '../components/CustomerInfoGroupsItem';
import Button from '../components/common/Button';
import DateFilter from '../components/common/DateFilter';
import { useQueryParams } from '../hooks/queryString';
import moment from 'moment';
import { format } from '../utils/number';
import CustomerPaymentCreate from './CustomerPaymentCreate';
import { getAbsent, getHomework, getLessonType, getMonthOptions, getPhoneOwnerLabel } from '../utils/position';

export default function CustomerDetail() {
	const queryParams = useQueryParams();
	const { customerId } = useParams();

	const customerDetail = useLoad({ url: CUSTOMER_DETAIL.replace('{id}', customerId) });
	const customer = customerDetail.response;

	const uploadAvatar = usePutRequest({ url: CUSTOMER_IMAGE.replace('{id}', customerId) });

	const defaultEndDate = moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format(DATE_FORMAT);
	const defaultStartDate = moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format(DATE_FORMAT);
	const customerAssessment = useLoad({
		url: CUSTOMER_ASSESSMENT.replace('{customerId}', customerId),
		params: { start_date: defaultStartDate, end_date: defaultEndDate, ...queryParams },
	});
	const customerAssessmentItem = customerAssessment.response ? customerAssessment.response : [];

	const [transactionsPage, setTransactionsPage] = useState(1);
	const transactions = useLoad({
		url: PAYMENT_STUDENT.replace('{id}', customerId),
		params: { customer: customerId, page: transactionsPage, pageSize: 5 },
	});

	const [showGroupCreateModal, hideGroupCreateModal] = useModal(
		<CustomerInfoGroupsCreate
			customer={customer}
			onCancel={() => hideGroupCreateModal()}
			onSuccess={() => {
				hideGroupCreateModal();
				customerDetail.request();
			}}
		/>
	);

	const [showPaymentHistoryModal, hidePaymentHistoryModal] = useModal(
		<CustomerPaymentCreate
			onSuccess={() => {
				hidePaymentHistoryModal();
				transactions.request();
				customerDetail.request();
			}}
			customer={customer}
		/>
	);

	if (!cp('staff.view_employee')) {
		return <PermissionDenied />;
	}

	async function uploadImage({ target }) {
		const image = target.files[0];
		const newData = new FormData();
		newData.append('studentImage', image, image.name);
		const { success, response } = await uploadAvatar.request({ data: newData });
		if (success) customerDetail.setResponse({ ...customer, studentImage: response.studentImage });
	}

	console.log(customer);

	return (
		<Layout>
			<NavLink style={{ marginRight: '1rem' }} className='button is-white' to='/academy/customers'>
				<i className='icon has-text-link ion-md-arrow-back is-size-4' />
			</NavLink>

			<span className='is-size-4'>Информация о студенте : {customer ? customer.name : ''}</span>
			<hr />

			{!customerDetail.loading && customer ? (
				<main style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
					{/* {cp('staff.add_employeeinvitation') && !customer.user && !customer.invited ? (
                                <b>Данный сотрудник не зарегистрирован в системе!</b><br/>
                                Что пригласить нажмите на кнопку наже и введите емейл сотрудника.
                                Мы отправим ему(ей) писмо с ссылкой на приватную страницу быстрой регистрации.<br/>
                                <br/>

                                <Button
                                    text="Пригласить"
                                    icon="ion-md-person-add"
                                    onClick={showInviteModal}
                                    className="is-primary is-small"/>
                            </div>
                        </article>
                    ) : null} */}

					<label className={cn(css(styles.default))}>
						<img width={140} height={140} alt=''
							 src={customer.studentImage ? domain + customer.studentImage : defaultAvatar}/>

						<input className={css(styles.input)} type='file' name='studentImage' onChange={uploadImage}/>
					</label>


					<article style={{ flex: 'auto' }}>
						<div className='columns'>
							<b className='column'>Ф.И.О: </b>
							<span className='column'>{customer.name}</span>

							<b className='column'>Тип группы:</b>
							<span className='column'>{getLessonType(customer?.lessonTypes)}</span>

							{/* <b className="column">
                                    Доля учителя:
                                    </b>
                                <span className="column">
                                    {customer.academyShare}%
                                </span> */}
						</div>

						<div className='columns'>
							<b className='column'>Дата рождения: </b>
							<span className='column'>{customer.birthday ? getDateNotRU(customer.birthday) :
								<span className='tag is-light'>нет дня рождения</span>}</span>
							<b className='column'>Примечание: </b>
							<span className='column'>{customer.description}</span>
						</div>

						<div className='columns'>
							<b className='column'>Канал продаж: </b>
							<span className='column'>{customer.advert.name}</span>
							<b className='column'>Менеджер продаж: </b>
							<span className='column'>{customer.seller.name}</span>
						</div>

						<div className='columns'>
							<b className='column'>Дата добавления: </b>
							<span className='column'>{getDateTime(customer.createdAt)}</span>

							<b className='column'>Номер телефона: </b>
							<div className='column' style={{ display: 'flex', flexDirection: 'column' }}>
								<span>
									{customer?.phone} - {getPhoneOwnerLabel(customer?.phoneOwner)}
								</span>
								{customer?.phoneSecond && (
									<span>
										{customer.phoneSecond} - {getPhoneOwnerLabel(customer?.phoneSecondOwner)}
									</span>
								)}
								{customer?.phoneThird && (
									<span>
										{customer.phoneThird} - {getPhoneOwnerLabel(customer?.phoneThirdOwner)}
									</span>
								)}
							</div>
						</div>

						<div className='columns'>
							<b className='column'>Предмет: </b>
							<div className='column' style={{ display: 'flex', flexDirection: 'column' }}>
								{customer?.subject?.map(item => (
									<span key={item?.id}>{item?.name}</span>
								))}
							</div>
							<b className='column'>Учитель: </b>
							<div className='column' style={{ display: 'flex', flexDirection: 'column' }}>
								{customer?.teacher?.map(item => (
									<span key={item?.id}>{item?.name}</span>
								))}
							</div>
						</div>
					</article>
				</main>
			) : (
				<Loader large center/>
			)}
			<hr/>

			<span className='is-size-4'>
				Группы
				<Button text='Добавить' onClick={showGroupCreateModal}
						className={cn('is-outlined is-info is-small is-pulled-right', css(styles.createButton))}
						icon='ion-md-add'/>
			</span>

			<Table
				loading={customerDetail.loading}
				items={customerDetail.response?.students || []}
				columns={{
					index: '',
					name: 'Группы',
					subject: 'Предмет',
					salary: 'Цена',
					startDate: 'Дата начала',
					endDate: 'Дата окончания',
					isMain: 'Положение дел',
					actions: '',
				}}
				renderItem={(item, index) => (
					<CustomerInfoGroupsItem
						item={item}
						index={index}
						key={item.id}
						customer={customer}
						// onUpdate={onUpdate}
						createStudent={customerDetail}
					/>
				)}
			/>

			<hr />

			<div style={{ marginTop: '2rem' }} className='is-size-4'>
				История оплаты
				<Button text='Добавить' onClick={showPaymentHistoryModal} className={cn('is-outlined is-info is-small is-pulled-right', css(styles.createButton))} icon='ion-md-add' />
			</div>

			<Table
				activePage={transactionsPage}
				onPageChange={setTransactionsPage}
				totalCount={transactions.response?.count || 0}
				columns={{
					index: '',
					amount: 'Сумма',
					month: 'Месяц',
					wallet: 'Счет',
					group: 'Группа',
					createdAt: 'Дата создания',
					description: 'Описание'
				}}
				loading={transactions.loading}
				items={transactions.response ? transactions.response.results : []}
				renderItem={(transaction, index) => (
					<tr key={transaction.id}>
						<td>{index + 1}</td>
						<td className={transaction.amount > 0 ? 'has-text-success' : 'has-text-danger'}>{format(transaction.amount)}</td>

						<td>{getMonthOptions(transaction?.month)}</td>
						<td>{transaction.wallet.name}</td>
						<td>{transaction.student.group.name}</td>
						<td>{moment(transaction.createdAt).calendar()}</td>
						<td>{transaction.description}</td>
					</tr>
				)}
			/>

			<div style={{ marginBottom: '2rem' }}>
				<hr />
			</div>

			{/* <span className="is-size-4">История зарплат</span>

            <Table
                loading={employeeSalary.loading}
                totalCount={employeeSalary.response ? employeeSalary.response.length : 0}
                pageSize={employeeSalary.response ? employeeSalary.response.length : 0}
                items={employeeSalary.response ? employeeSalary.response : []}
                columns={{ index: '№', name: 'Месяц', salary: 'Зарплата', actions: '' }}
                renderItem={(item, index) => (
                    <EmployeeSalaryItem index={index} key={item.month} item={item} employee={customer}/>
                )}/>

            <br/> */}

			{!customerAssessment.loading ? (
				<>
					{customerAssessmentItem.map((assessment, rowIndex) => (
						<div key={rowIndex}>
							<hgroup style={{ display: 'flex', alignItems: 'center', rowGap: '1rem', columnGap: '3rem', marginBottom: '2rem', flexWrap: 'wrap' }}>
								<span className='is-size-4'>{assessment.student.group.name}</span>
								<div style={{ display: 'flex', alignItems: 'center', columnGap: '1rem', flex: 'auto', justifyContent: 'flex-end' }}>
									<span>
										<DateFilter name='start_date' defaultValue={defaultStartDate} />
									</span>
									<span>
										<DateFilter name='end_date' defaultValue={defaultEndDate} />
									</span>
								</div>
							</hgroup>
							<Table
								columns={{ index: '', date: 'Число', isAbsent: 'Посещаемость', mark: 'Оценка', comment: 'Комментарий', homework: 'Домашнее задание', testScore: 'Количество тестов' }}
								items={assessment?.assessmentItems || []}
								renderItem={(assessmentItem, index) => (
									<tr key={assessmentItem?.id}>
										<td>{index + 1}</td>
										<td>{getDateNotRU(assessmentItem.createdAt)}</td>
										<td>{assessmentItem.isAbsent ? '✅ Kelgan' : `❌ Kelmagan(${getAbsent(assessmentItem.isAbsentStatus)})`}</td>
										<td>{assessmentItem.mark}</td>
										<td>{assessmentItem.comment ? assessmentItem.comment : <span className='is-small tag is-info is-light'>no comment</span>}</td>
										<td>{getHomework(assessmentItem.homeworkStatus)}</td>
										<td>{assessmentItem.testScore}</td>
									</tr>
								)}
							/>

							{!assessment.assessmentItems.length && <div className='has-text-grey-light is-italic has-text-centered'>{!assessment.hasMore && !assessment.loading && assessment.length !== 0 ? 'Студент еще не зачислен на этот курс' : ''}</div>}
						</div>
					))}
				</>
			) : (
				<Loader large center />
			)}
		</Layout>
	);
}

const styles = StyleSheet.create({
	default: { placeContent: 'center', alignItems: 'center', display: 'flex', borderRadius: '5px', width: '200px', height: '190px', cursor: 'pointer' },
	input: { width: 'auto', display: 'none' },
	icon: { width: '100%', fontSize: '2em', margin: '2rem 1rem' },
	height: { height: '100%', marginRight: 5 },
	buttonAction: { '@media (max-width: 500px)': { display: 'flex', marginTop: 10, justifyContent: 'center' } },
});
