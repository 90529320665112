import React from 'react';
import { STUDENT_LIST } from '../urls';
import { useInfiniteScroll } from '../hooks/request';
import Table from '../components/common/Table';
import StudentItem from '../components/StudentItem';
import { useQueryParams } from '../hooks/queryString';
import { checkForAdmin } from '../utils/auth';

export default function CustomStudentListModal({ groupId }) {
	const isAdmin = checkForAdmin();
	const params = useQueryParams();
	const students = useInfiniteScroll({ url: STUDENT_LIST, params: { group: groupId, state: params.state, ...params } }, []);
	const studentsList = students.response?.results || [];
	const activeStudentsList = studentsList.filter(item => item.isActive === true);

	return (
		<div>
			<Table
				columns={{ index: '№', student: 'Имя', phone: 'Телефон', totalPiad: 'Оплачено', monthPaid: 'Опл. в этом месяце', balance: 'Баланс', actions: '' }}
				items={isAdmin ? activeStudentsList : activeStudentsList}
				renderItem={(item, index) => <StudentItem students={students} index={index} key={item.id} onSuccess={students.request} onPaymentCreate={students.request} item={item} />}
				children={
					<div ref={students.ref} className='has-text-grey-light is-italic has-text-centered'>
						{!students.hasMore && !students.loading && students.length !== 0 ? 'Загрузили всех студенты' : ''}
					</div>
				}
			/>
		</div>
	);
}
