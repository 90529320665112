/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import cn from 'classnames';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { GROUPS_ARCHIVE, GROUPS_DETAIL } from '../urls';
import { useDeleteRequest, usePutRequest } from '../hooks/request';
import { useModal } from '../hooks/modal';
import { checkPermission as cp } from '../utils/auth';
import { useMessage } from '../hooks/message';
import { day } from '../utils/date';
import Loader from './common/Loader';
import GroupUpdate from './GroupUpdate';
import GroupTimelineSync from './GroupTimelineSync';
import { PermissionDeniedModal } from './PermissionDeniedModal';
import CustomStudentListModal from '../pages/CustomList';

export default function GroupItem({ item, onDelete, onUpdate, index }) {
	const groupDelete = useDeleteRequest({ url: GROUPS_DETAIL.replace('{id}', item.id) });
	const [showStudents, setShowStudents] = useState(false);
	const handleShowStudents = () => setShowStudents(!showStudents);
	const [showDropdown, setShowDropdown] = useState(false);
	const changeArchiveGroup = usePutRequest({ url: GROUPS_ARCHIVE.replace('{id}', item.id) });

	const isSuperAdmin = JSON.parse(localStorage.getItem('user')).isSuperuser;
	const [showPermissionDenied, hidePermissionDenied] = useModal(<PermissionDeniedModal onSuccess={() => hidePermissionDenied()} onCancel={() => hidePermissionDenied()} />);

	const [showUpdateModal, hideUpdateModal] = useModal(
		<GroupUpdate
			group={item}
			onUpdate={() => {
				onUpdate();
				hideUpdateModal();
			}}
			onCancel={() => hideUpdateModal()}
		/>
	);

	const [showTimelineModal, hideTimelineModal] = useModal(
		<GroupTimelineSync
			group={item}
			onSuccess={() => {
				onUpdate();
				hideTimelineModal();
			}}
			onCancel={() => hideTimelineModal()}
		/>
	);

	async function archiveGroup() {
		await changeArchiveGroup.request({ data: { isArchive: !item.isArchive } });
		onUpdate();
	}

	const [showMessage] = useMessage();

	async function deleteGroup() {
		if (global.confirm('Вы действительно хотите удалить?')) {
			const { error } = await groupDelete.request();
			if (error) {
				showMessage(error.data.detail, 'is-danger');
				return;
			}
			onDelete();
		}
	}

	return (
		<>
			<tr className={item.isArchive ? 'has-text-grey' : 'has-text-dark'}>
				<td>{index + 1}</td>
				<td>
					<NavLink to={`/academy/class/${item.id}`} className={item.isArchive ? 'has-text-grey' : ''}>
						{item.name}
					</NavLink>
				</td>

				<td>
					<div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
						{item.timelines.map(timeline => (
							<span key={timeline.id}>{day(timeline.day).shortTitle} &nbsp;</span>
						))}

						{cp('academy.delete_customer') ? (
							<span
								style={{
									display: 'flex',
									gap: '4px',
									alignItems: 'center',
									justifyContent: 'space-between',
								}}
							>
								{item.timelines[0] ? `в ${item.timelines[0].startTime.slice(0, 5)}` : 'Добавить расписание занятий'}
								<i className='icon ion-md-create pointer' onClick={showTimelineModal} />
							</span>
						) : null}
					</div>
				</td>

				<td>
					<Link to={`/academy/group/${item.id}/students-list`}>
						<p className={item.isArchive ? 'has-text-grey' : ''}>
							<span className='has-text-success'>{item.acceptedStudentsCount} студент</span>
						</p>
					</Link>
				</td>

				<td>{item.teacher ? item.teacher.name : ''}</td>
				<td>{item.subject ? item.subject.name : ''}</td>

				<td>{item.room ? item.room.name : ''}</td>

				<td>
					<div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
						<p onClick={handleShowStudents}>{showStudents ? <i className='icon pointer ion-md-remove' /> : <i className='icon pointer ion-md-add' />}</p>

						<Link to={'/academy/group/' + item?.id}>
							<i className='icon pointer ion-md-eye' />
						</Link>

						{cp('academy.delete_customer') ? !groupDelete.loading ? <i onClick={() => (isSuperAdmin ? deleteGroup() : showPermissionDenied())} className='icon pointer ion-md-trash' /> : <Loader className='icon' /> : null}

						{cp('academy.change_customer') ? <i onClick={showUpdateModal} className='icon pointer ion-md-create' /> : null}

						{cp('academy.change_group') ? (
							<div className={cn('dropdown is-right', { 'is-active': showDropdown })} onMouseLeave={() => setShowDropdown(false)}>
								<div className='dropdown-trigger pointer'>
									<i className='icon ion-md-more' onClick={() => setShowDropdown(!showDropdown)} />
								</div>

								<div className='dropdown-menu' id='dropdown-menu'>
									<div className='dropdown-content'>
										{cp('finance.change_wallet') && !item.isArchive ? (
											<a className='dropdown-item' onClick={archiveGroup}>
												<i className='icon ion-md-checkmark' />
												Отправить в архив
											</a>
										) : (
											<a className='dropdown-item' onClick={archiveGroup}>
												<i className='icon ion-md-checkmark' />
												Вытащит из архива
											</a>
										)}
									</div>
								</div>
							</div>
						) : null}
					</div>
				</td>
			</tr>
			{showStudents && (
				<tr>
					<td colSpan='8'>
						<CustomStudentListModal groupId={item.id} />
					</td>
				</tr>
			)}
		</>
	);
}
